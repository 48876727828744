<template>
  <b-container fluid class="overflow-auto">
    <!-- Publisher Interface controls -->
    <h5 class="my-3"><strong>PUBLISHERS</strong></h5>
    <a-row class="mb--1">
      <a-col :xl="4" :md="12" :xs="24">
      <a-checkbox v-model="showActive">
        Show only active
      </a-checkbox></a-col>
      <a-col :xl="4" :md="12" :xs="24">
          <a-select allowClear placeholder="Account Manager" style="width: 100%" :showSearch="true" :filterOption="true"
            optionFilterProp="children" v-model="selectedPublisher" @change="getFilteredPublishers">
            <a-select-option v-for="obj in user.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.FirstName }} {{ obj.LastName
            }}</a-select-option>
          </a-select>
        </a-col>
    </a-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'ortb-publishers'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-publishers-columns'" :options="fields"></columnSelect>
          <CreateButton   target="publisher" />
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :small="true"
      :bordered="true"
      :striped="true"
      :hover="true"
      show-empty
      stacked="md"
      :items="showPublishers"
      :fields="filteredFields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :tbody-tr-class="rowClass"
      @filtered="onFiltered"
    >
      <template #cell(bannerzones)="data">
       <div v-if="data.item.BannerzonesCount>1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/bannerzones'" @click="showBannerZones(data.item, data.item.BannerzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.BannerzonesCount }} zones</b-link> -->
          <b-link :to="redirectTo('publisher-bannerzones', { id: data.item.Id })" @click="showBannerZones(data.item, data.item.BannerzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.BannerzonesCount }} zones</b-link>
       </div>
        <div v-else-if="data.item.BannerzonesCount === 1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/bannerzones'" @click="showBannerZones(data.item, data.item.BannerzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.BannerzonesCount }} zone</b-link> -->
          <b-link :to="redirectTo('publisher-bannerzones', { id: data.item.Id })" @click="showBannerZones(data.item, data.item.BannerzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.BannerzonesCount }} zone</b-link>
        </div>
        <div v-else-if="data.item.Status === 0">
          <!-- <b-link :to="'/display/bannerzone/create/' + data.item.Id" @click="showBannerZones(data.item, data.item.BannerzonesCount)" style="color:rgb(105, 151, 244)"> Create zone</b-link> -->
          <b-link :to="redirectTo('create-bannerzone-pubId', { publisherId: data.item.Id })" @click="showBannerZones(data.item, data.item.BannerzonesCount)" style="color:rgb(105, 151, 244)"> Create zone</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(Popfeeds)="data">
       <div v-if="data.item.BannerzonesCount>1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/popfeeds'" @click="showPopFeeds(data.item, data.item.PopfeedsCount)" style="color:rgb(105, 151, 244)">{{ data.item.PopfeedsCount }} feeds</b-link> -->
          <b-link :to="redirectTo('publisher-popfeeds', { id: data.item.Id })" @click="showPopFeeds(data.item, data.item.PopfeedsCount)" style="color:rgb(105, 151, 244)">{{ data.item.PopfeedsCount }} feeds</b-link>
       </div>
        <div v-else-if="data.item.PopfeedsCount === 1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/popfeeds'" @click="showPopFeeds(data.item, data.item.PopfeedsCount)" style="color:rgb(105, 151, 244)">{{ data.item.PopfeedsCount }} zone</b-link> -->
          <b-link :to="redirectTo('publisher-popfeeds', { id: data.item.Id })" @click="showPopFeeds(data.item, data.item.PopfeedsCount)" style="color:rgb(105, 151, 244)">{{ data.item.PopfeedsCount }} feed</b-link>
        </div>
        <div v-else-if="data.item.Status === 0">
          <!-- <b-link :to="'/display/bannerzone/create/' + data.item.Id" @click="showPopFeeds(data.item, data.item.PopfeedsCount)" style="color:rgb(105, 151, 244)"> Create zone</b-link> -->
          <b-link :to="redirectTo('create-popfeed-publisherId', { publisherId: data.item.Id })" @click="showPopFeeds(data.item, data.item.PopfeedsCount)" style="color:rgb(105, 151, 244)"> Create feed</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(videozones)="data">
       <div v-if="data.item.VideozonesCount>1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/videozones'" @click="showVideoZones(data.item, data.item.VideozonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.VideozonesCount }} zones</b-link> -->
          <b-link :to="redirectTo('publisher-videozones', { id: data.item.Id })" @click="showVideoZones(data.item, data.item.VideozonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.VideozonesCount }} zones</b-link>
       </div>
        <div v-else-if="data.item.VideozonesCount === 1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/videozones'" @click="showVideoZones(data.item, data.item.VideozonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.VideozonesCount }} zone</b-link> -->
          <b-link :to="redirectTo('publisher-videozones', { id: data.item.Id })" @click="showVideoZones(data.item, data.item.VideozonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.VideozonesCount }} zone</b-link>
        </div>
        <div v-else-if="data.item.Status === 0">
          <!-- <b-link :to="'/display/videozone/create/' + data.item.Id" @click="showVideoZones(data.item, data.item.VideozonesCount)" style="color:rgb(105, 151, 244)"> Create zone</b-link> -->
          <b-link :to="redirectTo('create-videozone-pubId', { publisherId: data.item.Id })" @click="showVideoZones(data.item, data.item.VideozonesCount)" style="color:rgb(105, 151, 244)"> Create zone</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(rtbzones)="data">
       <div v-if="data.item.RtbzonesCount>1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/rtbzones'" @click="showRTBZones(data.item, data.item.RtbzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.RtbzonesCount }} zones</b-link> -->
          <b-link :to="redirectTo('publisher-rtbzones', { id: data.item.Id })" @click="showRTBZones(data.item, data.item.RtbzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.RtbzonesCount }} zones</b-link>
       </div>
        <div v-else-if="data.item.RtbzonesCount === 1">
          <!-- <b-link :to="'publisher/' + data.item.Id + '/rtbzones'" @click="showRTBZones(data.item, data.item.RtbzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.RtbzonesCount }} zone</b-link> -->
          <b-link :to="redirectTo('publisher-rtbzones', { id: data.item.Id })" @click="showRTBZones(data.item, data.item.RtbzonesCount)" style="color:rgb(105, 151, 244)">{{ data.item.RtbzonesCount }} zone</b-link>
        </div>
        <div v-else-if="data.item.Status === 0">
          <!-- <b-link :to="'/display/rtbzone/create/' + data.item.Id" @click="showRTBZones(data.item, data.item.RtbzonesCount)" style="color:rgb(105, 151, 244)"> Create zone</b-link> -->
          <b-link :to="redirectTo('create-rtbzone-pubId', { publisherId: data.item.Id })" style="color:rgb(105, 151, 244)"> Create zone</b-link>
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="togglePublisherStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Country)="data">
        <div class="items-center"><img :src="matchIcon(countries, data.item.Country)" /> {{ $store.getters['info/countryName'](data.item.Country) }}</div>
      </template>
      <template #cell(Id)="data">
        <div class="items-center">{{ data.item.Id }}</div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">{{ data.item.Name }}</div>
      </template>
      <template #cell(Description)="data">
        <div class="items-center">{{ data.item.Description }}</div>
      </template>
      <template #cell(Phone)="data">
        <div class="items-center">{{ data.item.Phone }}</div>
      </template>
      <template #cell(Skype)="data">
        <div class="items-center">{{ data.item.Skype }}</div>
      </template>
      <template #cell(Website)="data">
        <div class="items-center">{{ data.item.Website }}</div>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop :to="{ name: 'publisher-update', params: { id: data.item.Id },  }">
        <b-button
          size="sm"
          class="btn-info mr-1"
          @click="editPublisher(data.item.Id)"
        >
          <i class="fa fa-edit"></i>
        </b-button>
      </router-link>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import staticCountryData from '@/resources/countries.json'

export default {
  components: {
    columnSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data: function () {
    return {
      countries: staticCountryData,
      selectedPublisher: [],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:70px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          headerTitle: 'ID',
          sortable: true,
          thStyle: 'width:50px',
          tdClass: 'text-right',
        },
        {
          key: 'Mail',
          label: 'Email',
          show: false,
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'Username',
          label: 'Username',
          show: true,
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: true,
          sortable: true,
        },
        {
          key: 'Bannerzones',
          label: 'Banner Zones',
          headerTitle: 'Banner Zones',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'Videozones',
          label: 'Video Zones',
          headerTitle: 'Video Zones',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'Rtbzones',
          label: 'RTB Zones',
          headerTitle: 'RTB Zones',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'Popfeeds',
          label: 'Pop Feeds',
          headerTitle: 'Pop Feeds',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'Website',
          label: 'Website',
          show: true,
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'Country',
          label: 'Country',
          show: true,
          sortable: true,
          thStyle: 'width:160px',
        },
        {
          key: 'StatsAuth',
          label: 'Stats Auth',
          headerTitle: 'Stats API endpoint authentication code',
          show: false,
          sortable: true,
          thStyle: 'width:110px',
        },
        {
          key: 'City',
          label: 'City',
          show: false,
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'ZipCode',
          label: 'Zip Code',
          show: false,
          sortable: true,
          thStyle: 'width:100px',
        },
        {
          key: 'Address',
          label: 'Address',
          show: false,
          sortable: true,
          thStyle: 'width:200px',
        },
        {
          key: 'Phone',
          label: 'Phone',
          show: true,
          sortable: true,
          thStyle: 'width:140px',
        },
        {
          key: 'Skype',
          label: 'Skype',
          show: true,
          sortable: true,
          thStyle: 'width:130px',
        },
        {
          key: 'PlatformDomain',
          label: 'Platform Domain',
          show: true,
          headerTitle: 'Platform Domain',
          sortable: true,
        },
        {
          key: 'SellersJsonDomain',
          label: 'sellers.json Domain',
          show: true,
          headerTitle: 'sellers.json Domain',
          sortable: true,
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['info', 'publisher', 'settings', 'user']),
    ...mapGetters('ORTBpublisher', ['getPublishers', 'getActivePublishers']),
    showActive: {
      get: function () {
        return this.settings.showActivePublishers
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActivePublishers', value })
      },
    },
    showPublishers() {
      return this.showActive ? this.getActivePublishers : this.getPublishers
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showPublishers.length
    },
  },
  methods: {
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    getFilteredPublishers() {
      this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_FOR_USER', { AccManagerId: this.selectedPublisher, PublisherId: this.selectedPublisher })
    },
    showBannerZones(publisher, bannerzonesCount) {
      if (bannerzonesCount > 0) {
        this.$router.push({ name: 'publisher-bannerzones', params: { id: publisher.Id } }).catch(() => {})
      } else {
        this.$router.push({ name: 'create-bannerzone-pubId', params: { publisherId: publisher.Id } }).catch(() => {})
      }
    },
    showPopfeeds(publisher, count) {
      if (count > 0) {
        this.$router.push({ name: 'publisher-popfeeds', params: { id: publisher.Id } }).catch(() => {})
      } else {
        this.$router.push({ name: 'create-popfeed-publisherId', params: { publisherId: publisher.Id } }).catch(() => {})
      }
    },
    redirectTo(route, data = null) {
      return this.$router.resolve({ name: route, params: data }).resolved.path
    },
    showVideoZones(publisher, videozonesCount) {
      if (videozonesCount > 0) {
        // this.$router.push('/display/publisher/' + publisher.Id + '/videozones')
        this.$router.push({ name: 'publisher-videozones', params: { id: publisher.Id } }).catch(() => {})
      } else {
        // this.$router.push('/display/videozone/create/' + publisher.Id)
        this.$router.push({ name: 'create-videozone-pubId', params: { publisherId: publisher.Id } }).catch(() => {})
      }
    },
    showRTBZones(publisher, RtbzonesCount) {
      if (RtbzonesCount > 0) {
        // this.$router.push('/display/publisher/' + publisher.Id + '/rtbzones')
        this.$router.push({ name: 'publisher-rtbzones', params: { id: publisher.Id } }).catch(() => {})
      } else {
        // this.$router.push('/display/rtbzone/create/' + publisher.Id)
        this.$router.push({ name: 'create-rtbzone-pubId', params: { publisherId: publisher.Id } })
      }
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editPublisher(id) {
      // this.$router.push('/display/publisher/update/' + id)
      this.$router.push({ name: 'publisher-update', params: { id: id } }).catch(() => {})
    },
    togglePublisherStatus(publisher) {
      const status = publisher.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change publisher status?',
        content: h => <div>{status} publisher <strong>{publisher.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = publisher.Status === 0 ? 1 : 0
          this.$store.dispatch('ORTBpublisher/CHANGE_STATUS', {
            id: publisher.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS')
    this.$store.dispatch('user/LOAD_USERS')
  },
}
</script>
